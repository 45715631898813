var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"request-infocupo"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"d-flex w-100"},[_c('span',{staticClass:"d-flex align-items-center me-auto",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('nextStep', 3)}}},[_c('svg',{staticClass:"me-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"8.914","height":"14.492","viewBox":"0 0 8.914 14.492"}},[_c('path',{attrs:{"id":"Trazado_25","data-name":"Trazado 25","d":"M.358.38a1.331,1.331,0,0,0,0,1.816L6.443,8.54a1.187,1.187,0,0,0,1.691.04l6-6.251A1.327,1.327,0,0,0,14.136.513a1.188,1.188,0,0,0-1.736,0L7.25,5.82,2.093.373A1.188,1.188,0,0,0,.358.38Z","transform":"translate(8.914 0) rotate(90)","fill":"#131314"}})]),_vm._v(" Volver ")])]),_c('h4',{staticClass:"mb-4"},[_vm._v("Código de seguridad")]),_c('p',{staticClass:"mx-auto mb-5 col-md-9 col-xl-7"},[_vm._v(_vm._s(_vm.dataSendCode.message))]),_c('div',{staticClass:"input-infocupo mx-auto position-relative mb-4 col-11 col-md-7"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-center w-100 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.codigo),expression:"codigo"}],staticClass:"payment-input payment-email-input",attrs:{"type":"number","placeholder":"Código","name":"codigo"},domProps:{"value":(_vm.codigo)},on:{"input":function($event){if($event.target.composing)return;_vm.codigo=$event.target.value}}}),_c('button',{staticClass:"payment-button-input btn-primary",on:{"click":function($event){return _vm.sendCode()}}},[_vm._v(" Validar ")]),(_vm.codigo)?_c('span',{staticClass:"newlatter-error-input"},[_vm._v("Código")]):_vm._e()])]),_c('div',{staticClass:"mx-auto mb-4 col-md-7 col-xl-7"},[_c('span',{staticClass:"msgError"},[_vm._v(_vm._s(_vm.msgErrorValidateCod))])]),_c('div',{staticClass:"mb-4"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"btn-resend",style:(_vm.reSendCodWPP || _vm.sendMessage
            ? 'animation-duration: 1s; animation-timing-function: linear; animation-name: fadeOut; opacity: .6; cursor: default; border: 1px solid black !important; margin-bottom: 1.5rem !important;'
            : ''),on:{"click":function($event){_vm.reSendCodWPP || _vm.sendMessage ? '' : _vm.resendCodCupo(2)}}},[_c('img',{attrs:{"src":require("@/assets/icons/whatsapp-icon-black.svg")}}),_c('p',{staticClass:"mb-0"},[_vm._v("Recibir Whatsapp")])]),_c('div',{staticClass:"btn-resend",style:(_vm.reSendCodCall || _vm.sendMessage
            ? 'animation-duration: 1s; animation-timing-function: linear; animation-name: fadeOut; opacity: .6; cursor: default; border: 1px solid black !important; margin-bottom: 1.5rem !important;'
            : ''),on:{"click":function($event){_vm.reSendCodCall || _vm.sendMessage ? '' : _vm.resendCodCupo(3)}}},[_c('img',{attrs:{"src":require("@/assets/icons/phone-icon.svg"),"alt":""}}),_c('p',{staticClass:"mb-0"},[_vm._v("Recibir Llamada")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mx-auto mb-4 px-0"},[_vm._v(" ¿No has recibido el código? "),_c('br'),_vm._v(" Selecciona un canal para el reenvío del código. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-7 mx-auto"},[_c('p',{staticClass:"m-0 w-100 mb-5 p-3",staticStyle:{"border-radius":"5px","background-color":"white"},attrs:{"id":"counter-cod"}})])
}]

export { render, staticRenderFns }