<template>
  <div>
    <div class="container-infocupo container-max-width">
      <!-- <h3 class="title-infocupo">Paga o consulta tu crédito</h3> -->
      <div class="content-steps-infocupo mt-5">
        <summary-cupo-quota v-if="step == 5" @nextStep="step = $event" />
        <request-info-cupo v-if="step == 1" @nextStep="step = $event" />
        <payment-method-info-cupo v-if="step == 2" @nextStep="step = $event" />
        <send-token-validate-info-cupo
          v-if="step == 3"
          @nextStep="step = $event"
        />
        <token-validate-info-cupo v-if="step == 4" @nextStep="step = $event" />
        <!-- <summary-cupo v-if="step == 5" @nextStep="step = $event"/> -->
        <div class="w-100 mt-4">
          <p>Recibimos los siguientes medios de pago:</p>
          <img
            class="mx-auto"
            src="@/assets/img/metodos-de-pago_2.svg"
            alt=""
          />
        </div>
      </div>
      <div class="help-infocupo">
        <p class="text-center">
          ¿Necesitas ayuda? Comunícate con nuestra línea de atención 323 274
          7474
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import RequestInfoCupo from '@/components/InfoCupo/RequestInfoCupo.vue'
import PaymentMethodInfoCupo from '@/components/InfoCupo/PaymentMethodInfoCupo.vue'
import SendTokenValidateInfoCupo from '@/components/InfoCupo/SendTokenValidateInfoCupo.vue'
import TokenValidateInfoCupo from '@/components/InfoCupo/TokenValidateInfoCupo.vue'
import SummaryCupoQuota from '@/components/InfoCupo/SummaryCupoQuota'
// import SummaryCupo from '@/components/InfoCupo/SummaryCupo.vue'

// import {mapActions} from 'vuex'
export default {
  components: {
    RequestInfoCupo,
    PaymentMethodInfoCupo,
    SummaryCupoQuota,
    SendTokenValidateInfoCupo,
    TokenValidateInfoCupo /*SummaryCupo*/,
  },
  data() {
    return {
      step: 1,
    }
  },
  watch: {
    step() {
      window.scrollTo(0, 0)
    },
  },
  mounted() {
    // this.$gtag.pageview(this.$route.fullPath)
    // this.sendCodeInfoCupo2();
  },
  // methods: {
  //     ...mapActions('user',['sendCodeInfoCupo2']),
  // },
}
</script>
