<template>
  <div class="request-infocupo">
    <div
      v-if="puede_abonar == 1 && facturas.length > 0 && actualStep == 1"
      class="w-100"
    >
      <div class="d-flex">
        <span
          class="d-flex align-items-center me-auto"
          style="cursor: pointer;"
          @click="$emit('nextStep', 1)"
        >
          <svg
            class="me-2"
            xmlns="http://www.w3.org/2000/svg"
            width="8.914"
            height="14.492"
            viewBox="0 0 8.914 14.492"
          >
            <path
              id="Trazado_25"
              data-name="Trazado 25"
              d="M.358.38a1.331,1.331,0,0,0,0,1.816L6.443,8.54a1.187,1.187,0,0,0,1.691.04l6-6.251A1.327,1.327,0,0,0,14.136.513a1.188,1.188,0,0,0-1.736,0L7.25,5.82,2.093.373A1.188,1.188,0,0,0,.358.38Z"
              transform="translate(8.914 0) rotate(90)"
              fill="#131314"
            />
          </svg>
          Volver
        </span>
      </div>
      <h4>Selecciona una de las siguientes opciones</h4>
      <p class="mx-auto">
        Hola, <strong>{{ dataCupo.cliente_nombre }}</strong
        >, te ofrecemos dos opciones para tu mayor comodidad, <br />
        realiza el <strong>pago anticipado</strong> de tu cupo y ahórrate hasta
        <strong>${{ new Intl.NumberFormat('de-DE').format(discount) }}</strong>
      </p>
      <div class="container-acordeon">
        <b-card-header
          class="header-tag-anticipado"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.accordion-1 block>
            <div class="w-100 d-flex justify-content-between">
              <div>
                <span class="pe-3">
                  <img src="@/assets/icons/icon-payment-card.svg" alt="" />
                </span>
                <span class="title-pago-anticipado"
                  >Realiza pago anticipado</span
                >
              </div>
              <img src="@/assets/icons/arrow-method-payment-right.svg" alt="" />
            </div>
          </div>
        </b-card-header>
        <b-collapse
          id="accordion-1"
          accordion="payment-methods"
          role="tabpanel"
          class="border-only-LRB"
        >
          <b-card-body>
            <p>Selecciona la factura que deseas pagar</p>
            <div class="table-responsive container-table-anticipado">
              <table class="table table-pago-anticipado">
                <tbody>
                  <tr class="table-title-pago-anticipado">
                    <th></th>
                    <th>No. factura</th>
                    <th>Total</th>
                    <th>Valor a pagar</th>
                    <th>Tu ahorro</th>
                  </tr>
                  <tr
                    v-for="(factura, index) in facturas"
                    :key="'data' + index"
                    class="tr-content-pago-anticipado"
                    :class="
                      selectPago == factura.factura_id
                        ? 'tr-content-pago-anticipado-select'
                        : ''
                    "
                    @click="selectPago = factura.factura_id"
                  >
                    <td>
                      <div
                        class="check-price-pay-cupo p-0 mb-0"
                        style="background-color: transparent"
                      >
                        <input
                          v-model="selectPago"
                          type="radio"
                          :value="factura.factura_id"
                        />
                      </div>
                      <!-- <input v-model="selectPago" class="radio-btn" type="radio" name="selected" :value="factura.factura_id"> -->
                    </td>
                    <td>
                      {{ factura.factura_tipo }}-{{ factura.factura_numero }}
                    </td>
                    <td>
                      ${{
                        new Intl.NumberFormat('de-DE').format(factura.vlr_total)
                      }}
                    </td>
                    <td>
                      ${{
                        new Intl.NumberFormat('de-DE').format(
                          factura.vlr_a_pagar,
                        )
                      }}
                    </td>
                    <td>
                      ${{
                        new Intl.NumberFormat('de-DE').format(
                          factura.vlr_descuento,
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button
              class="col-12 col-md-5 btn-primary mt-3"
              :class="selectPago == null ? 'disabled' : ''"
              @click="payAnticipated()"
            >
              Realizar Pago
            </button>
            <div class="col-12 mt-4">
              <span class="msgError">{{ errValor2 }}</span>
            </div>
          </b-card-body>
        </b-collapse>
        <div class="mb-4" />
        <b-card-header
          class="header-tag-anticipado"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.accordion-2 block>
            <div class="w-100 d-flex justify-content-between">
              <div>
                <span class="pe-3">
                  <img src="@/assets/icons/icon-payment-pse.svg" alt="" />
                </span>
                <span class="title-pago-anticipado"
                  >Pagar un valor personalizado</span
                >
              </div>
              <img src="@/assets/icons/arrow-method-payment-right.svg" alt="" />
            </div>
          </div>
        </b-card-header>
        <b-collapse
          id="accordion-2"
          accordion="payment-methods"
          role="tabpanel"
          class="border-only-LRB"
        >
          <b-card-body>
            <p class="text-center mx-auto mb-3">
              <strong>{{
                dataCupo.cliente_nombre
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}</strong
              >, ingresa el valor del pago a realizar
            </p>
            <div class="col-12 col-md-6 mx-auto py-3">
              <div class="position-relative ms-auto otro-valor-input">
                <input
                  v-model="price"
                  class="payment-input otro-volar w-100"
                  placeholder="Valor cuota"
                  type="number"
                  name="otro_valor"
                />
                <span v-if="price" class="newlatter-error-input"
                  >Valor cuota</span
                >
              </div>
            </div>
            <button
              class="col-12 col-md-6 mx-auto mb-4 CRÉDITO btn-primary"
              @click="payUWebCheckOutPayU()"
            >
              Realizar Pago
            </button>
            <div class="col-12 mt-4">
              <span class="msgError">{{ errValor }}</span>
            </div>
          </b-card-body>
        </b-collapse>
      </div>
    </div>
    <div
      v-if="!(puede_abonar == 1 && facturas.length > 0) && actualStep == 1"
      class="w-100"
    >
      <div class="d-flex">
        <span
          class="d-flex align-items-center me-auto"
          style="cursor: pointer;"
          @click="$emit('nextStep', 1)"
        >
          <svg
            class="me-2"
            xmlns="http://www.w3.org/2000/svg"
            width="8.914"
            height="14.492"
            viewBox="0 0 8.914 14.492"
          >
            <path
              id="Trazado_25"
              data-name="Trazado 25"
              d="M.358.38a1.331,1.331,0,0,0,0,1.816L6.443,8.54a1.187,1.187,0,0,0,1.691.04l6-6.251A1.327,1.327,0,0,0,14.136.513a1.188,1.188,0,0,0-1.736,0L7.25,5.82,2.093.373A1.188,1.188,0,0,0,.358.38Z"
              transform="translate(8.914 0) rotate(90)"
              fill="#131314"
            />
          </svg>
          Volver
        </span>
      </div>
      <h4 class="mb-4">Pagar cuota</h4>
      <p class="text-center mx-auto mb-2">
        A continuación realizaras un pago a nombre de
        <b>{{ dataCupo.cliente_nombre }}.</b>
      </p>
      <p class="mx-auto mb-4">Ingresa el valor de la cuota a cancelar.</p>
      <div class="col-12 col-md-9 col-xl-7 mx-auto px-md-3">
        <div v-if="dataCupo.total_deuda > 0" class="w-100">
          <!-- <label class="check-price-pay-cupo" for="minimo" style="min-height: 98px" :style="dataCupo.pago_minimo < 1 ? 'opacity: .5' : ''">
                        <input type="radio" id="minimo" :disabled="dataCupo.pago_minimo < 1" v-model="values" :value="'PM'">
                        <p>Pago mínimo: ${{new Intl.NumberFormat("de-DE").format(dataCupo.pago_minimo)}}</p>
                    </label> -->
          <!-- <label class="check-price-pay-cupo" for="total" style="min-height: 98px">
                        <input type="radio" v-model="values" id="total" :value="'PT'">
                        <p>Pago total: ${{new Intl.NumberFormat("de-DE").format(dataCupo.total_deuda)}}</p>
                    </label> -->
          <label class="check-price-pay-cupo" for="valor2">
            <div class="mx-auto d-flex align-items-center">
              <!-- <input type="radio" v-model="values" id="total" :value="'OV'"> -->
              <p class="pe-4">Otro valor:</p>
              <div class="position-relative ms-auto otro-valor-input mt-0">
                <input
                  v-model="priceInput"
                  class="payment-input otro-volar"
                  placeholder="Valor"
                  type="number"
                  name="otro_valor"
                  @focus="values = 'OV'"
                />
                <span v-if="priceInput" class="newlatter-error-input"
                  >Valor</span
                >
              </div>
            </div>
          </label>
          <div v-if="price > dataCupo.total_deuda * 1.05" class="w-100 mx-auto">
            <p
              class="msg-error-perfil w-100 my-3"
              style="background-color: #FFE7CB"
            >
              El monto a pagar supera la deuda total
            </p>
          </div>
          <button
            :style="
              price < 1 || price > dataCupo.total_deuda * 1.05
                ? 'opacity: .6'
                : ''
            "
            :disabled="
              price < 1 ||
                price > dataCupo.total_deuda + dataCupo.total_deuda * 0.05
            "
            class="btn-primary w-100 mb-5"
            @click="actualStep = 2"
          >
            Pagar
            <span>${{ new Intl.NumberFormat('de-DE').format(price) }}</span>
          </button>
        </div>
        <div v-else>
          <p class="alert-summary-data-cupo w-100 p-3">
            ¡Felicitaciones estas al día en tus pagos!
          </p>
        </div>
        <!-- <button @click="$emit('nextStep', 3)" class="secondary-button w-100">Consultar estado de cuenta</button> -->
      </div>
    </div>
    <div v-if="actualStep == 2">
      <div class="d-flex">
        <span
          class="d-flex align-items-center me-auto"
          style="cursor: pointer;"
          @click="actualStep = 1"
        >
          <svg
            class="me-2"
            xmlns="http://www.w3.org/2000/svg"
            width="8.914"
            height="14.492"
            viewBox="0 0 8.914 14.492"
          >
            <path
              id="Trazado_25"
              data-name="Trazado 25"
              d="M.358.38a1.331,1.331,0,0,0,0,1.816L6.443,8.54a1.187,1.187,0,0,0,1.691.04l6-6.251A1.327,1.327,0,0,0,14.136.513a1.188,1.188,0,0,0-1.736,0L7.25,5.82,2.093.373A1.188,1.188,0,0,0,.358.38Z"
              transform="translate(8.914 0) rotate(90)"
              fill="#131314"
            />
          </svg>
          Volver
        </span>
      </div>
      <div class="content-btns-infocupo">
        <h4 class="w-100 mb-5">Seleccionar método de pago</h4>
        <div class="box-summary-method">
          <b class="mb-3">Resumen de pago</b>
          <table class="w-100">
            <tr>
              <td class="text-start">
                {{ dataCupo.cliente_nombre }} <br />
                {{ dataCupo.documento }}
              </td>
              <td class="text-end">
                Total a pagar:
                <b>${{ new Intl.NumberFormat('de-DE').format(price) }}</b>
                <br />
                <span class="change-val" @click="actualStep = 1"
                  >Cambiar valor</span
                >
              </td>
            </tr>
          </table>
        </div>
        <div class="box-payment-method" @click="payUWebCheckOutBancolombia()">
          <p>
            <strong>Bancolombia</strong><br />
            Transfiere con tu cuenta Bancolombia
          </p>
          <div>
            <p class="span-click-pay">clic para pagar</p>
            <img
              class="img-payment-method-infocupo"
              src="@/assets/img/logo_boton_bancolombia.png"
              alt=""
            />
          </div>
        </div>
        <div class="box-payment-method" @click="payUWebCheckOutPayU()">
          <p>
            <strong>PayU</strong><br />Paga a traves de cualquier medio: PSE,
            Tarjeta de crédito o Efecty
          </p>
          <div>
            <p class="span-click-pay">clic para pagar</p>
            <img
              class="img-payment-method-infocupo"
              src="@/assets/img/logo_boton_payU.png"
              alt=""
            />
          </div>
        </div>
        <div class="ccol-11 col-md-9 mx-auto px-md-3">
          <span v-if="!validatePrice" class="msgError">{{ errValor }}</span>
        </div>
        <div v-if="price < 20000" class="col-11 col-md-9 mx-auto px-md-3">
          <p class="msg-error-perfil w-100" style="background-color: #FFE7CB">
            Recuerde: si desea realizar un pago por EFECTY, el valor mínimo
            permitido es de <b>$20.000</b>
          </p>
        </div>
        <!-- <p class="mx-auto">Si deseas conocer el pago mínimo a realizar y el detalle de tu estado de cuenta haz <span class="next-step-more-info-infocupo" >click aquí</span></p> -->
      </div>
    </div>
    <div id="payUWebChecOut" v-html="htmlPayU" />
  </div>
</template>
<script>
import { BCardHeader, BCollapse } from 'bootstrap-vue'

import $ from 'jquery'
import { mapState, mapActions } from 'vuex'
export default {
  components: { BCardHeader, BCollapse },
  data() {
    return {
      selectPago: null,
      validatePrice: false,
      errValor: '',
      price: null,
      priceInput: null,
      htmlPayU: '',
      values: 'OV',
      actualStep: 1,
    }
  },
  computed: {
    ...mapState('user', ['puede_abonar', 'discount', 'facturas', 'dataCupo']),
    /* eslint-disable */
    validatePrice1() {
      const re = /[0-9]/
      return re.test(this.price)
    },
  },
  watch: {
    values() {
      if (this.values == 'PM') {
        this.price = this.dataCupo.pago_minimo
      } else if (this.values == 'PT') {
        this.price = this.dataCupo.total_deuda
      } else if (this.values == 'OV') {
        this.price = this.priceInput
      }
    },
    priceInput() {
      if (this.values == 'OV') {
        this.price = this.priceInput
      }
    },
    actualStep() {
      ;(this.validatePrice = true), window.scrollTo(0, 0)
    },
  },
  methods: {
    ...mapActions('user', []),
    async payAnticipated() {
      if (this.selectPago > null) {
        this.errValor2 = ''
        this.$store.state.loaderDerek = true
        let data = new FormData()
        data.append('documento', this.dataCupo.documento)
        data.append('factura_id', this.selectPago)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/prontoPago/chargeWebCheckoutProntoPago',
          data: data,
        })
          .then(response => {
            this.htmlPayU = response.data
            setTimeout(function() {
              $('#form__PagarCuotaCupoPayu').submit()
            }, 1000)
          })
          .catch(err => {
            this.$store.state.loaderDerek = false
            if (err.response.data.message) {
              this.errValor2 = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor2 = message
            }
          })
      }
    },
    async payUWebCheckOutPayU() {
      if (this.price > 0) {
        this.errValor = ''
        this.validatePrice = false
        this.$store.state.loaderDerek = true
        let data = new FormData()
        data.append('documento', this.dataCupo.documento)
        data.append('valor_abono', this.price)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/cuotaCupo/payu/webCheckout',
          data: data,
        })
          .then(response => {
            this.htmlPayU = response.data
            setTimeout(function() {
              $('#form__PagarCuotaCupoPayu').submit()
            }, 1000)
          })
          .catch(err => {
            this.$store.state.loaderDerek = false
            if (err.response.data.message) {
              this.errValor = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor = message
            }
          })
      } else {
        this.validatePrice = true
      }
    },
    async payUWebCheckOutBancolombia() {
      if (this.price > 0) {
        this.errValor = ''
        this.validatePrice = false
        this.$store.state.loaderDerek = true
        let data = new FormData()
        data.append('documento', this.dataCupo.documento)
        data.append('valor_abono', this.price)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/apiBancolombia/generate_buy',
          data: data,
        })
          .then(response => {
            location.href = response.data.urlPayment
          })
          .catch(err => {
            this.$store.state.loaderDerek = false
            console.log(err, 'Error en WebChecOut!!!')
            if (err.response.data.message) {
              this.errValor = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor = message
            }
          })
      } else {
        this.validatePrice = true
      }
    },
  },
}
</script>
