<template>
  <div class="request-infocupo">
    <div class="col-md-12">
      <div class="d-flex w-100">
        <span
          class="d-flex align-items-center me-auto"
          style="cursor: pointer;"
          @click="$emit('nextStep', 3)"
        >
          <svg
            class="me-2"
            xmlns="http://www.w3.org/2000/svg"
            width="8.914"
            height="14.492"
            viewBox="0 0 8.914 14.492"
          >
            <path
              id="Trazado_25"
              data-name="Trazado 25"
              d="M.358.38a1.331,1.331,0,0,0,0,1.816L6.443,8.54a1.187,1.187,0,0,0,1.691.04l6-6.251A1.327,1.327,0,0,0,14.136.513a1.188,1.188,0,0,0-1.736,0L7.25,5.82,2.093.373A1.188,1.188,0,0,0,.358.38Z"
              transform="translate(8.914 0) rotate(90)"
              fill="#131314"
            />
          </svg>
          Volver
        </span>
      </div>
      <h4 class="mb-4">Código de seguridad</h4>
      <p class="mx-auto mb-5 col-md-9 col-xl-7">{{ dataSendCode.message }}</p>
      <div
        class="input-infocupo mx-auto position-relative mb-4 col-11 col-md-7"
      >
        <div class="d-flex flex-wrap justify-content-center w-100 mb-3">
          <input
            v-model="codigo"
            class="payment-input payment-email-input"
            type="number"
            placeholder="Código"
            name="codigo"
          />
          <button class="payment-button-input btn-primary" @click="sendCode()">
            Validar
          </button>
          <span v-if="codigo" class="newlatter-error-input">Código</span>
        </div>
      </div>
      <div class="mx-auto mb-4 col-md-7 col-xl-7">
        <span class="msgError">{{ msgErrorValidateCod }}</span>
      </div>
      <div class="mb-4">
        <p class="mx-auto mb-4 px-0">
          ¿No has recibido el código? <br />
          Selecciona un canal para el reenvío del código.
        </p>
        <div class="col-12 col-md-7 mx-auto">
          <p
            id="counter-cod"
            class="m-0 w-100 mb-5 p-3"
            style="border-radius: 5px; background-color: white;"
          ></p>
        </div>
        <!-- <div
          class="btn-resend"
          :style="
            reSendCodMSG || sendMessage
              ? 'animation-duration: 1s; animation-timing-function: linear; animation-name: fadeOut; opacity: .6; cursor: default; border: 1px solid black !important; margin-bottom: 1.5rem !important;'
              : ''
          "
          @click="reSendCodMSG || sendMessage ? '' : resendCodCupo(1)"
        >
          <img src="@/assets/icons/sms-icon.svg" alt="" />
          <p class="mb-0">Reenviar SMS</p>
        </div> -->
        <div
          class="btn-resend"
          :style="
            reSendCodWPP || sendMessage
              ? 'animation-duration: 1s; animation-timing-function: linear; animation-name: fadeOut; opacity: .6; cursor: default; border: 1px solid black !important; margin-bottom: 1.5rem !important;'
              : ''
          "
          @click="reSendCodWPP || sendMessage ? '' : resendCodCupo(2)"
        >
          <img src="@/assets/icons/whatsapp-icon-black.svg" />
          <p class="mb-0">Recibir Whatsapp</p>
        </div>
        <div
          class="btn-resend"
          :style="
            reSendCodCall || sendMessage
              ? 'animation-duration: 1s; animation-timing-function: linear; animation-name: fadeOut; opacity: .6; cursor: default; border: 1px solid black !important; margin-bottom: 1.5rem !important;'
              : ''
          "
          @click="reSendCodCall || sendMessage ? '' : resendCodCupo(3)"
        >
          <img src="@/assets/icons/phone-icon.svg" alt="" />
          <p class="mb-0">Recibir Llamada</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      codigo: '',
      reSendCodWPP: false,
      reSendCodMSG: false,
      reSendCodCall: false,
      sendMessage: true,
    }
  },
  computed: {
    ...mapState('user', ['dataCupo', 'dataSendCode', 'msgErrorValidateCod']),
  },
  mounted() {
    this.reSendMessage(60)
  },
  destroyed() {
    this.$store.state.user.msgErrorValidateCod = ''
  },
  methods: {
    ...mapActions('user', ['validateCodeInfoCupo2']),
    async sendCode() {
      await this.validateCodeInfoCupo2({
        doc: this.dataCupo.documento,
        codigo: this.codigo,
      }).then(() => {
        if (!this.msgErrorValidateCod) {
          this.$emit('nextStep', 5)
        }
      })
    },
    async resendCodCupo(step) {
      this.$store.state.loaderDerek = true
      let data = new FormData()
      data.append('documento', this.dataCupo.documento)
      if (step == 1) {
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/comunications/transactional/reenviarTokenSMSEstadoCuenta',
          data: data,
        }).then(() => {
          if (!this.errorCupo) {
            this.reSendCodMSG = true
            this.$store.state.loaderDerek = false
            this.reSendMessage(60)
          }
        })
      } else if (step == 2) {
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/comunications/transactional/reenviarTokenWhatsAppEstadoCuenta',
          data: data,
        }).then(() => {
          if (!this.errorCupo) {
            this.reSendCodWPP = true
            this.$store.state.loaderDerek = false
            this.reSendMessage(60)
          }
        })
      } else if (step == 3) {
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/comunications/transactional/reenviarTokenVozEstadoCuenta',
          data: data,
        }).then(() => {
          if (!this.errorCupo) {
            this.reSendCodCall = true
            this.$store.state.loaderDerek = false
            this.reSendMessage(60)
          }
        })
      }
    },
    async reSendMessage(time) {
      document.getElementById(
        'counter-cod',
      ).innerHTML = `<b>Pudes solicitar un nuevo código en ${time} segundos...</b>`
      if (time > 0) {
        await setTimeout(() => {
          this.reSendMessage(time - 1)
        }, 1000)
      } else {
        document.getElementById('counter-cod').style.display = 'none'
        this.sendMessage = false
      }
    },
  },
}
</script>
