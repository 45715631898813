<template>
  <div class="col-12 col-xl-10 mx-auto">
    <div
      v-if="dataSendCode && !modalErrorPagos"
      class="container-summary-quota p-3"
    >
      <h3 class="font-weight-bold mb-4 ps-md-5">Estado de cuenta</h3>
      <div
        class="d-flex flex-wrap col-11 px-0 pt-4 px-4 mx-auto"
        style="border-radius: 10px; background-color: white;"
      >
        <div class="col-12 col-lg-9 pe-lg-1 mb-3">
          <p class="mb-1">{{ dataSendCode.nombres }}</p>
          <p class="mb-1">{{ dataSendCode.nit }}</p>
          <p class="mb-1">Cel: {{ dataSendCode.celular }}</p>
          <p class="mb-1">{{ dataSendCode.email }}</p>
        </div>
        <div class="col-12 col-lg-3 ps-lg-2 mb-3">
          <p class="mb-1">
            Fecha de creación: {{ dataSendCode.fecha_creacion }}
          </p>
          <p>IP: {{ dataSendCode.ip }}</p>
        </div>
      </div>
      <div class="d-flex flex-wrap px-4 pt-4 pb-0">
        <div class="d-flex flex-wrap justify-content-center w-100">
          <div class="container-resumen mb-4">
            <p class="mb-1"><b>Disponible</b></p>
            <span class="price-summary-quota"
              >${{
                new Intl.NumberFormat('de-DE').format(dataSendCode.disponible)
              }}</span
            >
            <div class="container-progress">
              <div
                class="progress-quota"
                :style="
                  'width: ' +
                    (dataSendCode.disponible * 100) / dataSendCode.cupo +
                    '%;'
                "
              ></div>
            </div>
            <p class="mb-4">
              Cupo total: ${{
                new Intl.NumberFormat('de-DE').format(dataSendCode.cupo)
              }}
            </p>
            <p class="mb-1"><b>Fecha de aprobación</b></p>
            <p class="mb-0">{{ dataSendCode.fechaAprobacion }}</p>
          </div>
          <div class="container-resumen mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mb-1"><b>Pago mínimo</b></p>
                <p class="price-summary-quota mb-1">
                  ${{
                    new Intl.NumberFormat('de-DE').format(
                      dataSendCode.pagoMinimo,
                    )
                  }}
                </p>
              </div>
              <div class="d-none d-md-flex">
                <button class="btn-primary" style="min-height: 40px;">
                  <a href="https://portalpagos.derek.co" class="pagar-cuota">
                    Paga tu cuota
                  </a>
                </button>
              </div>
            </div>
            <p class="mb-1 mt-4"><b>Pago total</b></p>
            <p class="mb-0 d-flex">
              <span class="price-summary-quota"
                >${{
                  new Intl.NumberFormat('de-DE').format(dataSendCode.deudaTotal)
                }}</span
              >
              <span class="ver-detalle-cuota" @click="modalCartera = true"
                >Ver detalle</span
              >
            </p>
            <div class="d-flex d-md-none mt-4">
              <button class="btn-primary" style="min-height: 40px;">
                <a href="https://portalpagos.derek.co" class="pagar-cuota">
                  Paga tu cuota
                </a>
              </button>
            </div>
          </div>
          <div class="container-resumen mb-4">
            <p class="mb-2"><b>Últimos pagos</b></p>
            <p v-if="dataSendCode.ultPagos.length == 0">No registra pagos</p>
            <p
              v-for="(pago, index) in dataSendCode.ultPagos"
              v-else
              :key="index + 'ult-pagos'"
              class="mb-0"
            >
              <span
                >{{ pago.fecha }} - ${{
                  new Intl.NumberFormat('de-DE').format(pago.valor)
                }}</span
              >
              <br />
            </p>
          </div>
          <div class="container-resumen mb-4">
            <p class="mb-1"><b>Fecha límite de pago</b></p>
            <div v-if="dataSendCode.extractoResumido.length > 0">
              <span class="price-summary-quota"
                >{{ dataSendCode.proximoPago }} - ${{
                  new Intl.NumberFormat('de-DE').format(
                    dataSendCode.cuotaCancelar,
                  )
                }}</span
              >
              <button
                class="mt-4 btn-plan-pagos-quota"
                @click="modalPagos = true"
              >
                Ver plan de pagos
              </button>
            </div>
            <p v-else>No programado</p>
          </div>
        </div>
        <div class="container-last-shopping">
          <p class="w-100"><b>Últimas compras</b></p>
          <div
            v-if="dataSendCode.ultCompras.length > 0"
            class="d-flex flex-wrap w-100"
          >
            <div
              v-for="(compra, index) in dataSendCode.ultCompras"
              :key="index + 'ilt-compras'"
              class="container-shopping"
            >
              <p class="mb-1">
                <b>{{ compra.fecha }}</b>
              </p>
              <p>
                {{ compra.bodega_nombre }} <br />
                ${{ new Intl.NumberFormat('de-DE').format(compra.valor) }}
              </p>
            </div>
          </div>
          <p v-else>No registra compras realizadas</p>
        </div>
        <!-- <button v-if="true" @click="payment = true" class="btn-primary col-12 col-md-6 col-lg-4 mx-auto">
                    Pagar cuota
                </button>
                <div v-else class="col-12 col-md-6 col-xl-5 mx-auto">
                    <p style="background-color: #c6ecbb" class="font-weight-bold rounded p-3">¡Felicitaciones estas al día en tus pagos!</p>
                </div> -->
      </div>
    </div>
    <!-- <div class="col-md-5 col-xl-7 p-3 mx-auto">
            <div v-if="actualStep == 1" class="w-100">
                <div class="d-flex mb-4">
                    <span @click="payment = false" class="d-flex align-items-center me-auto" style="cursor: pointer;">
                        <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="8.914" height="14.492" viewBox="0 0 8.914 14.492">
                            <path id="Trazado_25" data-name="Trazado 25" d="M.358.38a1.331,1.331,0,0,0,0,1.816L6.443,8.54a1.187,1.187,0,0,0,1.691.04l6-6.251A1.327,1.327,0,0,0,14.136.513a1.188,1.188,0,0,0-1.736,0L7.25,5.82,2.093.373A1.188,1.188,0,0,0,.358.38Z" transform="translate(8.914 0) rotate(90)" fill="#131314"/>
                        </svg>
                        Volver
                    </span>
                </div>
                <h4 class="mb-4">Pagar cuota</h4>
                <p class="text-center mx-auto mb-2">A continuación realizaras un pago a nombre de <b>{{dataSendCode.nombres}} {{dataSendCode.apellidos}}.</b></p>
                <p class="mx-auto mb-4">Ingresa el valor de la cuota a cancelar.</p>
                <div class="col-12 col-xl-7 mx-auto px-md-3">
                    <div class="w-100" v-if="dataSendCode.deudaTotal > 0">
                        <label class="check-price-pay-cupo" for="minimo" style="min-height: 98px">
                            <input type="radio" id="minimo" v-model="values" :value="'PM'">
                            <p>Pago mínimo: ${{new Intl.NumberFormat("de-DE").format(dataSendCode.pagoMinimo)}}</p>
                        </label>
                        <label class="check-price-pay-cupo" for="total" style="min-height: 98px">
                            <input type="radio" v-model="values" id="total" :value="'PT'">
                            <p>Pago total: ${{new Intl.NumberFormat("de-DE").format(dataSendCode.deudaTotal)}}</p>
                        </label>
                        <label class="check-price-pay-cupo flex-nowrap" for="valor2">
                            <input type="radio" style="min-width: 20px" id="valor2" v-model="values" :value="'OV'">
                            <div class="mx-auto d-flex flex-md-nowrap align-items-center">
                                <p style="width: 90px" class="text-start">Otro valor: </p>
                                <div class="position-relative otro-valor-input mt-0">
                                    <input class="payment-input otro-volar" @focus="values = 'OV'" placeholder="Valor" type="number" name="otro_valor" v-model="priceInput">
                                    <span class="newlatter-error-input" v-if="priceInput">Valor</span>
                                </div>
                            </div>
                        </label>
                        <div v-if="(dataSendCode.deudaTotal > 10000 && price > (dataSendCode.deudaTotal * 1.05)) || (10000 > dataSendCode.deudaTotal && price > 10000)" class="w-100 mx-auto">
                            <p class="msg-error-perfil w-100 my-3" style="background-color: #FFE7CB">El monto a pagar supera la deuda total</p>
                        </div>
                        <button :disabled="values == '' || 0 >= price || (dataSendCode.deudaTotal > 10000 && price > (dataSendCode.deudaTotal * 1.05)) || (10000 > dataSendCode.deudaTotal && price > 10000)" @click="actualStep = 2" :style="values == '' || 0 >= price || (dataSendCode.deudaTotal > 10000 && price > (dataSendCode.deudaTotal * 1.05)) || (10000 > dataSendCode.deudaTotal && price > 10000) ? 'opacity: .6' : ''" class="btn-primary w-100 mb-5">Pagar <span>${{new Intl.NumberFormat("de-DE").format(price)}}</span></button>
                    </div>
                    <div v-else>
                        <p class="alert-summary-data-cupo w-100 p-3">¡Felicitaciones estas al día en tus pagos!</p>
                    </div>
                </div>
            </div>
            <div v-if="actualStep == 2">
                <div class="d-flex mb-4">
                    <span @click="actualStep = 1" class="d-flex align-items-center me-auto" style="cursor: pointer;">
                        <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="8.914" height="14.492" viewBox="0 0 8.914 14.492">
                            <path id="Trazado_25" data-name="Trazado 25" d="M.358.38a1.331,1.331,0,0,0,0,1.816L6.443,8.54a1.187,1.187,0,0,0,1.691.04l6-6.251A1.327,1.327,0,0,0,14.136.513a1.188,1.188,0,0,0-1.736,0L7.25,5.82,2.093.373A1.188,1.188,0,0,0,.358.38Z" transform="translate(8.914 0) rotate(90)" fill="#131314"/>
                        </svg>
                        Volver
                    </span>
                </div>
                <div class="content-btns-infocupo">
                    <h4 class="w-100 mb-5">Seleccionar método de pago</h4>
                    <div class="box-payment-method w-100" @click="payUWebCheckOutBancolombia()">
                        <p><strong>Bancolombia</strong><br> Transfiere con tu cuenta Bancolombia</p>
                        <div>
                            <span class="span-click-pay">clic para pagar</span>
                            <img class="img-payment-method-infocupo" src="@/assets/img/logo_boton_bancolombia.png" alt="">
                        </div>
                    </div>
                    <div class="box-payment-method w-100" @click="payUWebCheckOutPayU()">
                        <p><strong>PayU</strong><br>Paga a traves de cualquier medio: PSE, Tarjeta de crédito o Efecty</p>
                        <div>
                            <span class="span-click-pay">clic para pagar</span>
                            <img class="img-payment-method-infocupo" src="@/assets/img/logo_boton_payU.png" alt="">
                        </div>
                    </div>
                    <div class="w-100 px-md-3">
                        <span class="msgError" v-if="!validatePrice">{{errValor}}</span>
                    </div>
                    <div v-if="price < 20000" class="w-100">
                        <p class="msg-error-perfil w-100" style="background-color: #FFE7CB">Recuerde: si desea realizar un pago por EFECTY, el valor mínimo permitido es de <b>$20.000</b></p>
                    </div>
                </div>
            </div>
        </div> -->
    <b-modal
      v-if="!modalErrorPagos && dataSendCode"
      id="plan-de-pagos"
      v-model="modalPagos"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <img
        class="btn-close-add-to-cart"
        src="@/assets/icons/delete-gray-icon.svg"
        alt="boton de cerrar"
        @click="modalPagos = false"
      />
      <div class="">
        <h2 class="text-center mb-4"><b>Pagos pendientes</b></h2>
        <div>
          <table class="table b-table table-striped table-hover">
            <tr style="border-bottom: 1px solid gray;">
              <th class="text-center py-2">
                <b style="font-size: 1.1rem">Vencimiento</b>
              </th>
              <th class="text-center py-2">
                <b style="font-size: 1.1rem">Valor a pagar</b>
              </th>
            </tr>
            <tr
              v-for="(pago, index) in dataSendCode.extractoResumido"
              :key="index + 'pagos-pendientes'"
              style="border-bottom: 1px solid gray;"
              :style="index % 2 == 0 ? 'background-color: white' : ''"
            >
              <td class="text-center py-2">{{ pago.vencimiento }}</td>
              <td class="text-center py-2">
                <b>${{ new Intl.NumberFormat('de-DE').format(pago.saldo) }}</b>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="!modalErrorPagos && dataSendCode"
      id="detalle-de-cartera"
      v-model="modalCartera"
      centered
      hide-header
      hide-footer
    >
      <img
        class="btn-close-add-to-cart"
        src="@/assets/icons/delete-gray-icon.svg"
        alt="boton de cerrar"
        @click="modalCartera = false"
      />
      <h2 class="mb-3 pt-4 text-center"><b>Detalle Cartera</b></h2>
      <div class="p-4">
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start">Saldo vencido</p>
          <p class="col-6 text-end pe-2">
            ${{ new Intl.NumberFormat('de-DE').format(dataSendCode.vencido) }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start mb-0">Saldo sin vencer</p>
          <p class="col-6 mb-0 text-end pe-2">
            ${{ new Intl.NumberFormat('de-DE').format(dataSendCode.sinVencer) }}
          </p>
          <hr class="col-5 ms-auto mt-2 me-0" />
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start"><b>Total capital</b></p>
          <p class="col-6 text-end pe-2">
            ${{
              new Intl.NumberFormat('de-DE').format(dataSendCode.totalCartera)
            }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start">Saldo Intereses Mora</p>
          <p class="col-6 text-end pe-2">
            ${{ new Intl.NumberFormat('de-DE').format(dataSendCode.intereses) }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start">Gastos cobranza</p>
          <p class="col-6 text-end pe-2">
            ${{
              new Intl.NumberFormat('de-DE').format(dataSendCode.gastosCobro)
            }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start">Honorarios</p>
          <p class="col-6 text-end pe-2">
            ${{
              new Intl.NumberFormat('de-DE').format(dataSendCode.honorarios)
            }}
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p v-if="dataSendCode.papeleria > 0" class="col-6 text-start mb-0">
            Papelería
          </p>
          <p v-if="dataSendCode.papeleria > 0" class="col-6 mb-0 text-end pe-2">
            ${{ new Intl.NumberFormat('de-DE').format(dataSendCode.papeleria) }}
          </p>
          <hr class="col-5 ms-auto mt-2 me-0" />
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <p class="col-6 text-start"><b>Deuda total</b></p>
          <p class="col-6 text-end pe-2">
            <b
              >${{
                new Intl.NumberFormat('de-DE').format(dataSendCode.deudaTotal)
              }}</b
            >
          </p>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="error-plan-de-pagos"
      v-model="modalErrorPagos"
      no-close-on-backdrop
      centered
      hide-header
      hide-footer
    >
      <div class="px-4 py-5">
        <h2 class="mb-4 text-center"><b>Parece que algo salió mal</b></h2>
        <p class="text-center">{{ messageError }}</p>
        <button class="w-100 btn-primary">
          <a href="https://portalpagos.derek.co" class="pagar-cuota">
            Entendido
          </a>
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      modalPagos: false,
      modalCartera: false,
      payment: false,
      actualStep: 1,
      values: '',
      priceInput: null,

      selectPago: null,
      validatePrice: false,
      errValor: '',
      price: null,
      htmlPayU: '',
      dataSendCode: null,
      modalErrorPagos: false,
      messageError: '',
    }
  },
  computed: {
    ...mapState('user', ['dataCupo']),
    /* eslint-disable */
    validatePrice1() {
      const re = /[0-9]/
      return re.test(this.price)
    },
  },
  watch: {
    values() {
      if (this.values == 'PM') {
        this.price = this.dataSendCode.pagoMinimo
      } else if (this.values == 'PT') {
        this.price = this.dataSendCode.deudaTotal
      } else if (this.values == 'OV') {
        this.price = this.priceInput
      }
    },
    priceInput() {
      if (this.values == 'OV') {
        this.price = this.priceInput
      }
    },
    actualStep() {
      ;(this.validatePrice = true), window.scrollTo(0, 0)
    },
    payment() {
      window.scrollTo(0, 0)
    },
  },
  beforeCreate() {
    this.$store.state.layouts.menuLayoutDerek = 'menu-simple'
    this.$store.state.layouts.footerLayoutDerek = 'footer-simple'
  },
  destroyed() {
    this.changeMenu({ menu: 'menu-base' })
    this.changeFooter({ footer: 'footer-derek' })
  },
  async mounted() {
    this.$store.state.layouts.menuLayoutDerek = 'menu-simple'
    this.$store.state.layouts.footerLayoutDerek = 'footer-simple'
    this.$store.state.loader = true
    let data = new FormData()
    data.append('token', this.$route.params.token)
    await this.axios({
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: 'cupopersonal/accountStatus/validateToken',
      data: data,
    })
      .then(response => {
        this.dataSendCode = response.data
      })
      .catch(err => {
        if (err.response.status == 422) {
          this.messageError = err.response.data.message
          this.modalErrorPagos = true
        } else {
          location.href = "https://portalpagos.derek.co"
        }
      })
  },
  methods: {
    ...mapActions('layouts', ['changeMenu', 'changeFooter']),
    async payAnticipated() {
      if (this.selectPago > null) {
        this.errValor2 = ''
        this.$store.state.loaderDerek = true
        let data = new FormData()
        data.append('documento', this.dataCupo.documento)
        data.append('factura_id', this.selectPago)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/prontoPago/chargeWebCheckoutProntoPago',
          data: data,
        })
          .then(response => {
            this.htmlPayU = response.data
            setTimeout(function() {
              $('#form__PagarCuotaCupoPayu').submit()
            }, 1000)
          })
          .catch(err => {
            this.$store.state.loaderDerek = false
            if (err.response.data.message) {
              this.errValor2 = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor2 = message
            }
          })
      }
    },
    async payUWebCheckOutPayU() {
      if (this.price > 0) {
        this.errValor = ''
        this.validatePrice = false
        this.$store.state.loaderDerek = true
        let data = new FormData()
        data.append('documento', this.dataCupo.documento)
        data.append('valor_abono', this.price)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/cuotaCupo/payu/webCheckout',
          data: data,
        })
          .then(response => {
            this.htmlPayU = response.data
            setTimeout(function() {
              $('#form__PagarCuotaCupoPayu').submit()
            }, 1000)
          })
          .catch(err => {
            this.$store.state.loaderDerek = false
            if (err.response.data.message) {
              this.errValor = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor = message
            }
          })
      } else {
        this.validatePrice = true
      }
    },
    async payUWebCheckOutBancolombia() {
      if (this.price > 0) {
        this.errValor = ''
        this.validatePrice = false
        this.$store.state.loaderDerek = true
        let data = new FormData()
        data.append('documento', this.dataCupo.documento)
        data.append('valor_abono', this.price)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/apiBancolombia/generate_buy',
          data: data,
        })
          .then(response => {
            location.href = response.data.urlPayment
          })
          .catch(err => {
            this.$store.state.loaderDerek = false
            console.log(err, 'Error en WebChecOut!!!')
            if (err.response.data.message) {
              this.errValor = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor = message
            }
          })
      } else {
        this.validatePrice = true
      }
    },
  },
}
</script>
