<template>
  <div class="request-infocupo">
    <div class="d-flex w-100">
      <span
        class="d-flex align-items-center me-auto"
        style="cursor: pointer;"
        @click="$emit('nextStep', 1)"
      >
        <svg
          class="me-2"
          xmlns="http://www.w3.org/2000/svg"
          width="8.914"
          height="14.492"
          viewBox="0 0 8.914 14.492"
        >
          <path
            id="Trazado_25"
            data-name="Trazado 25"
            d="M.358.38a1.331,1.331,0,0,0,0,1.816L6.443,8.54a1.187,1.187,0,0,0,1.691.04l6-6.251A1.327,1.327,0,0,0,14.136.513a1.188,1.188,0,0,0-1.736,0L7.25,5.82,2.093.373A1.188,1.188,0,0,0,.358.38Z"
            transform="translate(8.914 0) rotate(90)"
            fill="#131314"
          />
        </svg>
        Volver
      </span>
    </div>
    <h4 class="mb-4">Pagar mi cupo personal</h4>
    <div v-if="!sendSMSDone" class="w-100">
      <p class="col-md-7 col-xl-6 mx-auto mb-4 text-start">
        <b>{{ infoClient.cliente_nombre }}</b> para proteger tus datos
        <b>DEREK.CO</b> quiere verificar que eres tú quien está intentando
        consultar el estado de cuenta.
      </p>
      <p class="col-md-7 col-xl-6 mx-auto mb-4 text-start">
        Confirma el número de teléfono que indicaste en la solicitud de cupo
        personal: {{ infoClient.celular }}
      </p>
      <div
        class="input-infocupo col-md-7 col-xl-6 position-relative mx-auto mb-4"
      >
        <input
          v-model="inputValTel"
          class="w-100 payment-input"
          placeholder="Ingresa número de celular"
          type="number"
        />
        <span v-if="inputValTel" class="newlatter-error-input"
          >Ingresa número de celular</span
        >
      </div>
      <div class="col-md-7 col-xl-6 mx-auto mb-4">
        <span v-if="errorCupo" class="msgError">{{ msgErrorCupo }}</span>
      </div>
      <div
        class="p-4 col-md-7 col-xl-6 mx-auto mb-4"
        style="background-color: #fbeee3; border-radius: 10px;"
      >
        <p class="mb-0">
          <b>¡Importante!</b> DEREK.CO en ningún caso solicitara compartir
          información de tu estado de cuenta, ante cualquier sospecha de fraude
          comunícate con nuestra línea de atención Whatsapp 323 274 7474
        </p>
      </div>
      <div class="col-md-7 col-xl-6 mx-auto">
        <button
          class="btn-primary w-100 button-infocupo"
          @click="ValidateTel()"
        >
          <div v-if="!loading">Continuar</div>
          <div v-else class="d-flex justify-content-center">
            Cargando...
            <div class="loader" />
          </div>
        </button>
      </div>
    </div>
    <div v-else>
      <div
        class="p-4 col-md-7 col-xl-6 mx-auto mb-4"
        style="background-color: #fbeee3; border-radius: 10px;"
      >
        <p class="mb-0">
          <b>{{ infoClient.cliente_nombre }}</b> hemos enviado un mensaje de
          texto a tu celular con la información de tu estado de cuenta.
        </p>
      </div>
      <p><b>NO COMPARTAS ESTA INFORMACIÓN CON NADIE</b></p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      sendSMSDone: false,
      inputValTel: '',
    }
  },
  computed: {
    ...mapState('user', [
      'dataCupo',
      'dataSendCode',
      'infoClient',
      'errorCupo',
      'msgErrorCupo',
    ]),
    validatePhone() {
      const re = /^3[0-9]{9}/
      return re.test(this.inputValTel) && this.inputValTel.length < 11
    },
  },
  methods: {
    ...mapActions('user', ['checkTelEstadoCuenta']),
    async ValidateTel() {
      if (this.validatePhone) {
        this.loading = true
        await this.checkTelEstadoCuenta({
          celular: this.inputValTel,
          documento: this.infoClient.documento,
        }).then(() => {
          if (!this.errorCupo) {
            this.sendSMSDone = true
          }
          this.loading = false
        })
      }
    },
  },
}
</script>
