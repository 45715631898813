<template>
  <div class="request-infocupo">
    <h4 class="mb-4 w-100">Pagar mi cupo personal</h4>
    <p class="px-0 w-100 mb-4">
      Por favor ingresa la <b>cédula</b> del titular.
    </p>
    <div class="input-infocupo col-md-6 position-relative mx-auto mb-4">
      <input
        v-model="doc"
        class="w-100 payment-input"
        placeholder="Ingresa número de cédula"
        type="number"
      />
      <span v-if="doc" class="newlatter-error-input"
        >Ingresa número de cédula</span
      >
    </div>
    <div class="d-flex flex-wrap col-12 col-md-6 mx-auto">
      <div class="col-12 col-md-6 pe-md-2 mb-4 mb-md-0">
        <button
          class="secondary-button px-2 w-100 button-infocupo"
          @click="ValidateDocEstado()"
        >
          <div v-if="!loading2">Ver estado cuenta</div>
          <div v-else class="d-flex justify-content-center">
            Cargando...
            <div class="loader" />
          </div>
        </button>
      </div>
      <div class="col-12 col-md-6 ps-md-2 ">
        <button
          class="btn-primary w-100 button-infocupo"
          @click="ValidateDoc()"
        >
          <div v-if="!loading">Continuar</div>
          <div v-else class="d-flex justify-content-center">
            Cargando...
            <div class="loader" />
          </div>
        </button>
      </div>
    </div>
    <div class="w-100">
      <span v-if="error" class="msgError">{{ msgError }}</span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      doc: null,
      msgError: '',
      loading: false,
      loading2: false,
      error: false,
    }
  },
  computed: {
    ...mapState('user', ['errorCupo', 'dataCupo', 'msgErrorCupo']),
  },
  async mounted() {
    if (this.$route.params.document) {
      this.$store.state.loaderDerek = true
      this.doc = this.$route.params.document
      await this.getInfoCupo({ doc: this.$route.params.document }).then(() => {
        this.$store.state.loaderDerek = false
        if (!this.errorCupo) {
          this.loading = false
          this.$emit('nextStep', 2)
        } else {
          this.loading = false
          this.error = this.errorCupo
          this.msgError = this.msgErrorCupo
        }
        delete this.$route.params.document
      })
    }
  },
  methods: {
    ...mapActions('user', ['getInfoCupo', 'checkEstadoCuenta']),
    async ValidateDoc() {
      this.error = false
      if (this.doc && this.doc.length > 5) {
        this.loading = true
        await this.getInfoCupo({ doc: this.doc }).then(() => {
          if (!this.errorCupo) {
            this.loading = false
            this.$emit('nextStep', 2)
          } else {
            this.loading = false
            this.error = this.errorCupo
            this.msgError = this.msgErrorCupo
          }
        })
      } else {
        this.error = true
        this.msgError = 'El documento es invalido'
      }
    },
    async ValidateDocEstado() {
      this.error = false
      if (this.doc && this.doc.length > 5) {
        this.loading2 = true
        await this.checkEstadoCuenta({ doc: this.doc }).then(() => {
          if (!this.errorCupo) {
            this.loading2 = false
            this.$emit('nextStep', 3)
          } else {
            this.loading2 = false
            this.error = this.errorCupo
            this.msgError = this.msgErrorCupo
          }
        })
      } else {
        this.error = true
        this.msgError = 'El documento es invalido'
      }
    },
  },
}
</script>
